import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import { composeWithDevTools } from "redux-devtools-extension";
import reducer from "./reducer";
import { restaurantName } from "../restaurantInfo";

const persistConfig = {
  key: `${restaurantName}_order`,
  storage
};

const persistedReducer = persistReducer(persistConfig, reducer);

/* eslint-disable no-underscore-dangle */
export const store = createStore(persistedReducer, composeWithDevTools());

export const persistor = persistStore(store);

/* eslint-enable */
