import axios from "axios";
import wrapWithProvider from "./wrap-with-provider";
import "./src/styles/global.css";
import { store } from "./src/state/createStore";
import { restaurantID } from "./src/restaurantInfo";
import "typeface-ibm-plex-sans";
import {
  setPickupAvailability,
  setDeliveryAvailability,
  setClosedMessage,
  setRestaurantAvailability,
  setDeliveryWaitMinutes,
  setPickupWaitMinutes,
  setSundayDeliveryAvailability,
  setMondayDeliveryAvailability,
  setMorningDelivery,
  setRedirectToNewWebsite,
} from "./src/state/actions/setOrderingAvailability";
import setMenu from "./src/state/actions/setMenu";
import setEggRollSideAvailable from "./src/state/actions/setEggRollSideAvailable";

export const wrapRootElement = wrapWithProvider;

function processFirebaseMenu(menu) {
  const { fields } = menu.mapValue;
  const fieldKeys = Object.keys(fields);

  const newMenu = {};

  fieldKeys.forEach(key => {
    const field = fields[key];
    const { values } = field.arrayValue;
    const numValues = Object.keys(values).length;
    if (numValues > 0) {
      const arr = [];
      for (let i = 0; i < numValues; i += 1) {
        const val = values[i].mapValue.fields;
        const valKeys = Object.keys(val);
        valKeys.forEach(k => {
          const vk = Object.keys(val[k]);
          val[k] = val[k][vk[0]];
        });
        if (val.enabled !== false) {
          arr.push(val);
        }
      }
      newMenu[key] = arr;
    }
  });

  return newMenu;
}

export const onClientEntry = () => {
  const dbRef = `https://firestore.googleapis.com/v1/projects/foodweb-central/databases/(default)/documents/restaurants/${restaurantID}/`;

  axios
    .get(dbRef)
    .then(res => {
      const { config, menu, eggRollSideAvailable } = res.data.fields;
      const {
        sundayDelivery,
        delivery,
        pickup,
        restaurant,
        closedMessage,
        deliveryWaitMinutes,
        pickupWaitMinutes,
        morningDelivery,
        mondayDelivery,
        redirectToNewWebsite,
      } = config.mapValue.fields.availability.mapValue.fields;
      store.dispatch(setMenu(processFirebaseMenu(menu)));
      store.dispatch(setPickupAvailability(pickup.booleanValue));
      store.dispatch(setDeliveryAvailability(delivery.booleanValue));
      store.dispatch(setRestaurantAvailability(restaurant.booleanValue));
      store.dispatch(setClosedMessage(closedMessage.stringValue));
      store.dispatch(setDeliveryWaitMinutes(deliveryWaitMinutes.stringValue));
      store.dispatch(setPickupWaitMinutes(pickupWaitMinutes.stringValue));
      store.dispatch(
        setEggRollSideAvailable(eggRollSideAvailable.booleanValue)
      );
      store.dispatch(
        setSundayDeliveryAvailability(sundayDelivery.booleanValue)
      );
      store.dispatch(
        setMondayDeliveryAvailability(mondayDelivery.booleanValue)
      );
      store.dispatch(setMorningDelivery(morningDelivery.booleanValue));
      store.dispatch(
        setRedirectToNewWebsite(redirectToNewWebsite.booleanValue)
      );
    })
    .catch(e => {
      console.log(e.message);
    });
};
