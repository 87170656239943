import update from "immutability-helper";
import merge from "deepmerge";
import { restaurantID, restaurantName } from "../restaurantInfo";
import * as Menu from "../components/menu/menuItems";

const initialState = {
  order: {
    items: [],
    tip: 0,
    orderType: "pickup",
    timeOrderFor: "As Soon As Possible",
    subtotal: 0,
    total: 0,
    paymentMethod: "Cash",
    withUtensils: "No",
  },
  eggRollSideAvailable: true,
  menu: undefined,
  customer: {},
  infoForServer: {
    restaurantID,
    restaurantName,
  },
  config: {
    availability: {
      pickup: true,
      delivery: true,
      restaurant: true,
      closedMessage:
        "The owner has temporarily disabled online ordering. Please try again later.",
      deliveryWaitMinutes: "60",
      pickupWaitMinutes: "30",
      redirectToNewWebsite: undefined,
    },
  },
};

const initStateCopy = merge({}, initialState);

const OrderReducer = (state = initStateCopy, action) => {
  switch (action.type) {
    case "SET_EGG_ROLL_SIDE_AVAILABLE":
      return update(state, {
        eggRollSideAvailable: {
          $set: action.payload,
        },
      });
    case "ADD_ITEM":
      return update(state, {
        order: {
          items: {
            $push: [action.item],
          },
        },
      });
    case "DELETE_ORDER":
      return update(state, {
        order: {
          items: {
            $splice: [[action.num, 1]],
          },
        },
      });
    case "SET_TIME_ORDER_FOR":
      return update(state, {
        order: {
          timeOrderFor: { $set: action.timeOrderFor },
        },
      });
    case "SET_PAYMENT_METHOD":
      return update(state, {
        order: {
          paymentMethod: { $set: action.paymentMethod },
        },
      });
    case "SET_EXP_DATE":
      return update(state, {
        order: {
          expDate: { $set: action.expDate },
        },
      });
    case "SET_CUSTOM_TIME":
      return update(state, {
        order: {
          customTime: { $set: action.customTime },
        },
      });
    case "CLEAR_ALL_ITEMS":
      return Object.assign({}, state, {
        ...state,
        order: {
          ...state.order,
          items: [],
        },
      });
    case "SET_SIDE":
      return update(state, {
        order: {
          items: {
            [action.itemNumber]: {
              sideChoice: { $set: action.sideChoice },
            },
          },
        },
      });
    case "SET_TIP":
      return update(state, {
        order: {
          tip: { $set: action.tip },
        },
      });
    case "SET_SAUCES":
      return update(state, {
        order: {
          items: {
            [action.itemNumber]: {
              sauces: { $set: action.sauces },
            },
          },
        },
      });
    case "SET_REQUESTS":
      return update(state, {
        order: {
          items: {
            [action.num]: {
              requests: { $set: action.req },
            },
          },
        },
      });
    case "SET_WITH_UTENSILS": {
      return update(state, {
        order: {
          withUtensils: { $set: action.withUtensils },
        },
      });
    }
    case "UPDATE_RN":
      return update(state, {
        order: {
          items: {
            [action.itemNumber]: {
              rnChoice: { $set: action.rnChoice },
              rnPrice: { $set: action.rnPrice },
            },
          },
        },
      });
    case "UPDATE_MEAT":
      return update(state, {
        order: {
          items: {
            [action.itemNumber]: {
              meatChoice: { $set: action.meatChoice },
              meatPrice: { $set: action.meatPrice },
            },
          },
        },
      });
    case "UPDATE_SIDE":
      return update(state, {
        order: {
          items: {
            [action.itemNumber]: {
              sideChoice: { $set: action.sideChoice },
            },
          },
        },
      });
    case "SET_ORDER_TYPE":
      return update(state, {
        order: {
          $unset: ["paymentMethod", "ccNumber", "expDate", "CVC"],
          orderType: { $set: action.orderType },
        },
      });
    case "SET_FIRST_NAME":
      return update(state, {
        customer: {
          firstname: { $set: action.firstname },
        },
      });
    case "SET_LAST_NAME":
      return update(state, {
        customer: {
          lastname: { $set: action.lastname },
        },
      });
    case "SET_PHONE":
      return update(state, {
        customer: {
          phone: { $set: action.phone },
        },
      });
    case "SET_SUBTOTAL":
      return update(state, {
        order: {
          subtotal: { $set: action.subtotal },
        },
      });
    case "SET_TOTAL":
      return update(state, {
        order: {
          total: { $set: action.total },
        },
      });
    case "SET_CC_NUMBER":
      return update(state, {
        order: {
          ccNumber: { $set: action.ccNumber },
        },
      });
    case "SET_CVC":
      return update(state, {
        order: {
          CVC: { $set: action.CVC },
        },
      });
    case "SET_ADDRESS":
      return update(state, {
        customer: {
          address: { $set: action.address },
        },
      });
    case "SET_UID":
      return update(state, {
        customer: {
          uid: { $set: action.uid },
        },
      });
    case "SET_ZIP":
      return update(state, {
        customer: {
          zip: { $set: action.zip },
        },
      });
    case "SET_PAYMENT_PREFERENCES":
      return update(state, {
        customer: {
          paymentPreferences: { $set: action.paymentPreferences },
        },
      });
    case "CLEAR_EVERYTHING":
      return merge({ menu: state.menu, config: state.config }, initStateCopy);
    case "SET_PICKUP_AVAILABILITY":
      return update(state, {
        config: {
          availability: {
            pickup: {
              $set: action.available,
            },
          },
        },
      });
    case "SET_DELIVERY_AVAILABILITY":
      return update(state, {
        config: {
          availability: {
            delivery: {
              $set: action.available,
            },
          },
        },
      });
    case "SET_SUNDAY_DELIVERY_AVAILABILITY":
      return update(state, {
        config: {
          availability: {
            sundayDelivery: {
              $set: action.available,
            },
          },
        },
      });
    case "SET_MONDAY_DELIVERY_AVAILABILITY":
      return update(state, {
        config: {
          availability: {
            mondayDelivery: {
              $set: action.available,
            },
          },
        },
      });
    case "SET_RESTAURANT_AVAILABILITY":
      return update(state, {
        config: {
          availability: {
            restaurant: {
              $set: action.available,
            },
          },
        },
      });
    case "SET_CLOSED_MESSAGE":
      return update(state, {
        config: {
          availability: {
            closedMessage: {
              $set: action.message,
            },
          },
        },
      });
    case "SET_DELIVERY_WAIT_MINUTES":
      return update(state, {
        config: {
          availability: {
            deliveryWaitMinutes: {
              $set: action.minutes,
            },
          },
        },
      });
    case "SET_PICKUP_WAIT_MINUTES":
      return update(state, {
        config: {
          availability: {
            pickupWaitMinutes: {
              $set: action.minutes,
            },
          },
        },
      });
    case "SET_MORNING_DELIVERY":
      return update(state, {
        config: {
          availability: {
            morningDelivery: {
              $set: action.morningDelivery,
            },
          },
        },
      });
    case "SET_REDIRECT_TO_NEW_WEBSITE":
      return update(state, {
        config: {
          availability: {
            redirectToNewWebsite: {
              $set: action.redirectToNewWebsite,
            },
          },
        },
      });
    case "SET_MENU":
      return update(state, {
        menu: {
          $set: action.menu,
        },
      });
    default:
      return state;
  }
};

export default OrderReducer;
