export const setPickupAvailability = available => ({
  type: "SET_PICKUP_AVAILABILITY",
  available,
});

export const setDeliveryAvailability = available => ({
  type: "SET_DELIVERY_AVAILABILITY",
  available,
});

export const setSundayDeliveryAvailability = available => ({
  type: "SET_SUNDAY_DELIVERY_AVAILABILITY",
  available,
});

export const setMondayDeliveryAvailability = available => ({
  type: "SET_MONDAY_DELIVERY_AVAILABILITY",
  available,
});

export const setRestaurantAvailability = available => ({
  type: "SET_RESTAURANT_AVAILABILITY",
  available,
});

export const setClosedMessage = message => ({
  type: "SET_CLOSED_MESSAGE",
  message,
});

export const setDeliveryWaitMinutes = minutes => ({
  type: "SET_DELIVERY_WAIT_MINUTES",
  minutes,
});

export const setPickupWaitMinutes = minutes => ({
  type: "SET_PICKUP_WAIT_MINUTES",
  minutes,
});

export const setMorningDelivery = morningDelivery => ({
  type: "SET_MORNING_DELIVERY",
  morningDelivery,
});

export const setRedirectToNewWebsite = redirectToNewWebsite => ({
  type: "SET_REDIRECT_TO_NEW_WEBSITE",
  redirectToNewWebsite,
});
